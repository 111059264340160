/**
 * Link
 */
export const GOOGLE_CALENDAR_LINK = `https://calendar.google.com/calendar/u/0/r/eventedit?dates=20231126T010000Z/20231126T080000Z&text=Maulid+Akbar+Albahjah+Jamblang&details=Maulid+Akbar+Albahjah+Jamblang`;
export const GOOGLE_MAPS_LINK = `https://goo.gl/maps/kfFTBJKwRhw3qWut8`;

/**
 * Wedding time
 */
export const EPOCH_START_EVENT = 1700956800;
export const EPOCH_END_EVENT = 1700956800;
export const UTC_WEDDING_TIME = '2023-11-26:04:00:00Z';
