import React, { Fragment } from 'react';
import { bool } from 'prop-types';

import Habib from '@assets/images/habib.jpg';
import Buya from '@assets/images/buya.jpg';
import Abi from '@assets/images/abi.jpg';
import Kh from '@assets/images/kh-abdul-kholiq.jpg';

import { styWrapper } from './styles';

function HelloSection({ isInvitation }) {
  const finalSubtitle = 'Minggu, 26 November 2023';

  return (
	<Fragment>
	  <div id="fh5co-couple" css={styWrapper}>
		<div className="container">
		  <div className="row">
			<div className="col-md-8 col-md-offset-2 text-center fh5co-heading">
			  <h2 className="main-font">Assalamualaikum Wr. Wb</h2>
			  <h3 className="sub-title hs">{finalSubtitle}</h3>
			  <p className="info">
			  Kini Saatnya❗Satukan Hati Untuk Baginda Nabi

			Hadir Kembali
			Maulid Nabi & Silaturrahim Akbar Satu Hati di Al Bahjah Jamblang

			Moment indah untuk meningkatkan kecintaan dan kerinduan kepada Nabi Muhammad SAW, serta memperkokoh ukhwah islamiyah di antara umat Islam

			Mari niatkan hati untuk bisa hadir, ajak serta keluarga, sahabat, kerabat dan orang-orang terkasih untuk dapat merasakan indahnya mengagungkan Nabi
			  </p>
			</div>
		  </div>
		  <div className="couple-wrap">
			<div className="couple-half">
			  <div className="groom">
				<img src={Abi} alt="groom" className="img-responsive" loading="lazy" />
			  </div>
			  <div className="desc-groom">
				<h3 className="main-font">Abi Arif</h3>
				<p className="parent-name parent-name__top">
				  Pengasuh Al Bahjah Jamblang
				</p>
			  </div>
			</div>
			<div className="couple-half">
			  <div className="bride">
				<img src={Kh} alt="groom" className="img-responsive" loading="lazy" />
			  </div>
			  <div className="desc-bride">
				<h3 className="main-font">KH Abdul kholiq</h3>
				<p className="parent-name">
				  Ayahanda Abi Arif
				</p>
			  </div>
			</div>
		  </div>
		  <div className="couple-wrap">
			<div className="couple-half">
			  <div className="groom">
				<img src={Buya} alt="groom" className="img-responsive" loading="lazy" />
			  </div>
			  <div className="desc-groom">
				<h3 className="main-font">Buya Yahya</h3>
				<p className="parent-name parent-name__top">
				  Pengasuh LPD AL-BAHJAH
				</p>
			  </div>
			</div>
			<div className="couple-half">
			  <div className="bride">
				<img src={Habib} alt="groom" className="img-responsive" loading="lazy" />
			  </div>
			  <div className="desc-bride">
				<h3 className="main-font">Habib Achmad Jamal Ba'agil</h3>
				<p className="parent-name">
				  Pengasuh PonPes Arrwarut Towfiq
				</p>
			  </div>
			</div>
		  </div>
		</div>
	  </div>
	</Fragment>
  );
}

HelloSection.propTypes = {
  isInvitation: bool.isRequired,
};

export default HelloSection;
